/* HowItWorks.css */
.how-it-works {
  background: var(--Neutral---Zircon, #f8f9ff);
  padding: 60px 20px;

  color: #333;
}

.how-it-works-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.how-it-works h2 {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 20px;
  color: #333;
}

.how-it-works-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.for-physicians,
.for-patients {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1;
  min-width: 300px;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid var(--CTA---Aqua-Squeeze, #e5f4f2);
  background: #fff;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05);
}

.for-physicians h3,
.for-patients h3 {
  font-size: 26px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 500;
}

.for-physicians p,
.for-patients p {
  color: #2d2d2d;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.icon-container {
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: 15px;
  background: rgba(80, 144, 255, 0.2);
  max-width: 10%;
}
