/* ContactForm.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Merriweather:wght@400;700&display=swap");

.contact-container {
  display: flex;
  padding: 60px 55px;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  background-color: #0a7ea4; /* Update if you want a darker shade to match the screenshot */
}

.contact-form-container {
  display: flex;
  padding: 60px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  border: 1px solid var(--CTA---Aqua-Squeeze, #e5f4f2);
  background: #fff;
  width: 40%;
}

.contact-form-container h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.contact-form-container p {
  font-size: 1rem;
  color: #555;
}

.contact-details {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-input {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.submit-button {
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #0a7ea4; /* Update if you want a darker shade to match the screenshot */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #005b4a;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 15px;
  }
  .contact-form-container {
    width: 100%;
  }
}
