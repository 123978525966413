.App {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100vh;
  position: relative;
  overflow: auto;
}

body {
  font-family: "Poppins", sans-serif;
}

.left-side {
  width: 100%;
}

.right-side {
  width: 100%;
}
