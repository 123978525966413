.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #0a7ea4; /* Update if you want a darker shade to match the screenshot */
  height: 100vh;
}

.hero-content {
  margin-top: -10%;
  display: flex;
  flex-direction: column;
  max-width: 70%;
  text-align: center;
}

.hero-content h2 {
  font-size: 68px; /* Increased font size for prominence */
  color: #fff; /* Light beige color similar to th  */
  font-weight: 600; /* Regular weight for a less bold look */
}

.subtitle {
  font-size: 28px; /* Smaller font size for the subtitle */
  font-family: "Merriweather", serif;
  margin-top: -4%;
  /* margin-bottom: 2rem; */
  color: #f8f9ff; /* Light gray for contrast */
  line-height: 1.5;
  font-weight: 400; /* Regular weight for a less bold look */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .hero {
    padding: 0 1rem;
    flex-direction: column;
    text-align: center;
    /* background: linear-gradient(
      135deg,
      #f0f4f8,
      #ffffff
    ); Light gradient for a soft background */
  }

  .hero-animation {
    display: none;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-content h2 {
    font-size: 36px; /* Smaller font size for mobile */
    line-height: 1.2; /* Slightly closer line height */
  }
  .subtitle {
    font-size: 28px; /* Adjusted subtitle size */
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .hero-content {
    max-width: 100%; /* Increase max-width for better readability on smaller screens */
    padding: 5 0.5rem;
  }

  .hero-content h2 {
    font-size: 36px; /* Adjust font size for smaller screens */
  }

  .subtitle {
    font-size: 24px;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .hero-content h2 {
    font-size: 36px; /* Further reduce font size for mobile */
  }

  .subtitle {
    font-size: 20px;
  }

  .description {
    font-size: 12px;
  }
}
