.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #0a7ea4; /* Update if you want a darker shade to match the screenshot */
  color: white;
}

.nav-links {
  list-style: none;
}

.nav-links li {
  display: inline;
}
.button {
  border-radius: 10px;
  border: none;
  width: 150px;
  height: 40px;
  color: black;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}
