.signup-form {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between the input and the button */
  width: 50%;
  margin-bottom: 20px;
}

.signup-form input[type="email"] {
  width: 70%;
  height: 50px;
  padding: 0 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fff; /* Light gray background for modern look */
  color: #333; /* Dark text color for readability */
  outline: none;
  font-size: 14px;
  font-weight: 400; /* Regular weight for a less bold look */

  transition: border-color 0.3s;
}

.signup-form input[type="email"]::placeholder {
  color: #888; /* Medium gray for placeholder text */
  font-size: 14px;
  font-weight: 400; /* Regular weight for a less bold look */
}

.signup-btn {
  width: 25%;
  height: 50px; /* Same height as the input */
  background-color: #333333;
  color: #fff;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600; /* Regular weight for a less bold look */

  transition: background-color 0.3s, color 0.3s;
}

.signup-btn:hover {
  background-color: rgba(240, 234, 214, 0.9);
  color: #000;
}

.confirmation-message {
  font-size: 18px;
  margin-top: 1rem;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .signup-form {
    flex-direction: column;
    align-items: center;
    /* height: 50px; */
  }
  .signup-form input {
    font-size: 11px;
  }
  .signup-form input[type="email"],
  .signup-form input[type="email"]::placeholder,
  .signup-btn {
    width: 100%;
    height: 50px;
    /* padding-left: 10%; */
    font-size: 14px;
    /* padding: 0; */
  }

  .confirmation-message {
    font-size: 16px;
    margin-top: 1rem;
    text-align: center;
  }
}
